import React from "react";

import MapMarker from "@pagerland/icons/src/line/MapMarker";
import textToMultiline from "@pagerland/common/src/utils/textToMultiline";
import Skype from "@pagerland/icons/src/line/Skype";
import Envelope from "@pagerland/icons/src/line/Envelope";
import FacebookF from "@pagerland/icons/src/line/FacebookF";
import Instagram from "@pagerland/icons/src/line/Instagram";
import Twitter from "@pagerland/icons/src/line/Twitter";
import Linkedin from "@pagerland/icons/src/line/Linkedin";
import Cog from "@pagerland/icons/src/line/Cog";
import Figma from "@pagerland/icons/src/line/Figma";
import LifeRing from "@pagerland/icons/src/line/LifeRing";

import Typography from "@pagerland/common/src/components/Typography";
import Img from "@pagerland/common/src/components/Img";

// import { smoothLinkProps } from "@pagerland/common/src/utils";

import maschable from "../assets/brands/maschable.svg";
import extremetech from "../assets/brands/extremetech.svg";
import techbragins from "../assets/brands/techbragins.svg";
import envato from "../assets/brands/envato.svg";
import geek from "../assets/brands/geek.svg";

import features1 from "../assets/features.svg";
import features2 from "../assets/features-2.svg";
import features3 from "../assets/features-3.svg";
import features4 from "../assets/features-4.svg";

import Screenshot from "../assets/screenshot.jpg";
import Screenshot2x from "../assets/screenshot@2x.jpg";
import Screenshot2 from "../assets/screenshot2.jpg";
import Screenshot22x from "../assets/screenshot2@2x.jpg";
import Screenshot3 from "../assets/screenshot3.jpg";
import Screenshot32x from "../assets/screenshot3@2x.jpg";
import Screenshot4 from "../assets/screenshot4.jpg";
import Screenshot42x from "../assets/screenshot4@2x.jpg";
import Screenshot5 from "../assets/screenshot5.jpg";
import Screenshot52x from "../assets/screenshot5@2x.jpg";
import Avatar from "../assets/avatar.jpg";
import Avatar2x from "../assets/avatar@2x.jpg";

export default {
  title: "Gorila Digital",
  navbar: {
    links: [
      {
        to: "",
        label: "Inicio",
      },
      {
        to: "about",
        label: "Nosotros",
      },
      {
        to: "features",
        label: "Cualidades",
      },
      {
        to: "screens",
        label: "Trabajos",
      },
      // {
      //   to: "clients",
      //   label: "Clients",
      // },
      {
        to: "contact",
        label: "Contact",
      },
    ],
    actions: [
      {
        href: "/",
        label: "English",
      },
    ],
  },
  welcome: {
    title: [
      "Gorila Digital. ",
      <Typography as="span" variant="h1" color="primary">
        Software
      </Typography>,
      " a medida!",
    ],
    text:
      "En Gorila Digital nos apasiona el Software y lo transmitimos con nuestro trabajo. Ponte cómodo y cuéntanos tu idea, nosotros nos encargamos de darle forma.",
    actions: [],
  },
  brands: [
    {
      src: maschable,
      alt: "Maschable",
      LinkProps: {
        href: "https://mypags.app",
        title: "Maschable",
      },
    },
    {
      src: extremetech,
      alt: "Extremetech",
      LinkProps: {
        href: "https://mypags.app",
        title: "Extremetech",
      },
    },
    {
      src: techbragins,
      alt: "Techbragins",
      LinkProps: {
        href: "https://mypags.app",
        title: "Techbragins",
      },
    },
    {
      src: envato,
      alt: "Envato",
      LinkProps: {
        href: "https://mypags.app",
        title: "Envato",
      },
    },
    {
      src: geek,
      alt: "Geek",
      LinkProps: {
        href: "https://mypags.app",
        title: "Geek",
      },
    },
  ],
  about: {
    sections: [
      {
        IconWrapperProps: {
          bg: "primary",
        },
        title: "Planificación y Estimación",
        text:
          "Cuéntanos que necesitas, volveremos a ti brevemente con una estimación de coste y tiempo. Seguidamente empezaremos a planificar el proyecto. Siempre estarás al tanto con nuestras live demos.",
        icon: Cog,
      },
      {
        IconWrapperProps: {
          bg: "secondary",
        },
        title: "Diseño y Desarrollo",
        text: textToMultiline(
          "Contamos con los mejores diseñadores que crearán interfaces centradas en la limpieza y usabilidad para proveer la mejor experiencia de usuario. \n Nuestros experimentados desarrolladores utilizarán las mejores prácticas para crear un software que durará años."
        ),
        icon: Figma,
      },
      {
        IconWrapperProps: {
          bg: "tertiary",
        },
        title: "Soporte inmejorable",
        text:
          "Puedes contar con nosotros. Después de entregar el producto, nuestra misión es proveer del mejor soporte a nuestros clientes. No te fallaremos.",
        icon: LifeRing,
      },
    ],
  },
  features: {
    title: "¿Qué podemos hacer por tí?",
    text:
      "Estamos especializados en multiples tecnologías. Esto nos hace más fácil la tarea de buscar la mejor solución para tu problema o darle forma a tu idea de la manera más productiva.",
    features: [
      {
        title: "Desarrollo Web",
        text:
          "Desde comercio electrónico a aplicaciones web, dashboards, panel de administración, data visualization, PWA... Te ayudamos a decidir qué es lo que mejor se adapta a tu negocio para hacerlo realidad.",
        details: <Img src={features1} width="100%" maxHeight={228} />,
      },
      {
        title: "Desarrollo Móvil",
        text:
          "Si tu idea encaja mejor con los dispositivos portátiles. Somos expertos en crear aplicaciones móviles de una manera rápida a un precio ajustado. Usamos las mejores tecnologías para agilizar el desarrollo para ambas plataformas: iOS y Android.",
        details: <Img src={features2} width="100%" maxHeight={256} />,
      },
      {
        title: "Juegos",
        text:
          "Si necesitas un mini juego para atraer nuevos usuarios o lanzar una campaña de marketing, podemos sugerirte ideas para popularizar tu producto.",
        details: <Img src={features3} width="100%" maxHeight={200} />,
      },
      {
        title: "Computer vision and Machine learning",
        text:
          "El desarrollo de software está evolucionando constantemente y tenemos más herramientas que nunca. Deja que una cámara sea tus ojos y un ordenador tu cerebro, utiliza tus datos para mejorar tu negocio.",
        details: <Img src={features4} width="100%" maxHeight={228} />,
      },
    ],
  },
  screenshots: {
    title: "Trabajos",
    screenshots: [
      {
        ImgProps: {
          src: Screenshot,
          srcSet: `${Screenshot} 1x, ${Screenshot2x} 2x`,
          alt: "Weddy Screenshot",
        },
        title: "Weddy Screenshot",
        text: "Weddy es una aplicación móvil para gestionar tu boda.",
      },
      {
        ImgProps: {
          src: Screenshot2,
          srcSet: `${Screenshot2} 1x, ${Screenshot22x} 2x`,
          alt: "Weddy Screenshot (2)",
        },
        title: "Weddy Screenshot (2)",
        text:
          "Puedes controlar todos los detalles de tu boda desde tu mano. Invitados, fotografías, regalos...",
      },
      {
        ImgProps: {
          src: Screenshot3,
          srcSet: `${Screenshot3} 1x, ${Screenshot32x} 2x`,
          alt: "Weddy Screenshot (3)",
        },
        title: "Weddy Screenshot (3)",
        text:
          "Todos los invitados tienen un perfil en la aplicación y pueden confirmar la asistencia mediante la APP",
      },
      {
        ImgProps: {
          src: Screenshot4,
          srcSet: `${Screenshot4} 1x, ${Screenshot42x} 2x`,
          alt: "Emotion recognition",
        },
        title: "Reconocimiento de emociones faciales en el coche (PoC)",
        text: textToMultiline(
          "Prueba de concepto que reconoce emociones faciales mientras conduces. Creada para una charla técnica en Shibuya (Tokyo). \n Para hacer una demo divertida se utilizó el juego de Mario Kart."
        ),
      },
      {
        ImgProps: {
          src: Screenshot5,
          srcSet: `${Screenshot5} 1x, ${Screenshot52x} 2x`,
          alt: "Coche conectado",
        },
        title: "Coche conectado a la nube",
        text: textToMultiline(
          "Esta applicación está conectada a una API real en la nube. Provee funcionalidades para cambiar datos reales del coche como la presión de los neumáticos. \n Conectando esta aplicación a la nube se puede mostrar a los clientes los distintos servicios sin tener que utilizar un coche real."
        ),
      },
    ],
  },
  clients: {
    title: "See what other people say about us",
    avatar: {
      src: Avatar,
      srcSet: `${Avatar} 1x, ${Avatar2x} 2x`,
      alt: "Avatar",
    },
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras fringilla mi sit amet malesuada euismod. Morbi pulvinar mauris et erat hendrerit blandit. Duis euismod lectus in eros varius, sed mattis leo posuere. In dapibus semper orci et convallis. Cras posuere dui at turpis viverra fermentum.",
    author: "Courtney Pena, Los Angeles",
  },
  pricing: {
    title: "Pricing",
    prices: {
      currency: "USD",
      types: {
        a: "Monthly",
        b: "Annually",
      },
      sections: [
        {
          title: "Beginner",
          price: "Free",
          period: "Forever",
          features: [
            {
              text: "user",
              prefix: "1",
            },
            {
              text: "clients",
              prefix: "2",
            },
            {
              text: "projects",
              prefix: "5",
            },
            {
              text: "tasks",
              prefix: "No",
            },
            {
              text: "subtasks",
              prefix: "No",
            },
            {
              text: "invoicing",
              prefix: "No",
            },
            {
              text: "estimating",
              prefix: "No",
            },
          ],
          button: {
            text: "Try now",
            ButtonProps: {
              as: "a",
              href: "https://mypags.app",
            },
          },
        },
        {
          title: "Advanced",
          price: "$25",
          period: "per month",
          features: [
            {
              text: "user",
              prefix: "1",
            },
            {
              text: "clients",
              prefix: "Unlimited",
            },
            {
              text: "projects",
              prefix: "Unlimited",
            },
            {
              text: "tasks",
              prefix: "Unlimited",
            },
            {
              text: "subtasks",
              prefix: "No",
            },
            {
              text: "invoices per month",
              prefix: "10",
            },
            {
              text: "estimates per month",
              prefix: "10",
            },
          ],
          button: {
            text: "Try now",
            ButtonProps: {
              as: "a",
              href: "https://mypags.app",
            },
          },
        },
        {
          title: "Expert",
          price: "$99",
          period: "per month",
          features: [
            {
              text: "user",
              prefix: "25",
            },
            {
              text: "clients",
              prefix: "Unlimited",
            },
            {
              text: "projects",
              prefix: "Unlimited",
            },
            {
              text: "tasks",
              prefix: "Unlimited",
            },
            {
              text: "subtasks",
              prefix: "Unlimited",
            },
            {
              text: "invoicing",
              prefix: "Unlimited",
            },
            {
              text: "estimating",
              prefix: "Unlimited",
            },
          ],
          button: {
            text: "Try now",
            ButtonProps: {
              as: "a",
              href: "https://mypags.app",
            },
          },
        },
      ],
    },
  },
  cta: {
    title: "Do you like what you see?",
    button: {
      label: "Buy it on Themeforest",
      href:
        "https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567",
    },
  },
  socialLinks: [
    {
      icon: Linkedin,
      href: "https://www.linkedin.com/company/gorila-digital/",
      title: "Linkedin",
    },
    {
      icon: FacebookF,
      href: "https://www.facebook.com/Gorila-Digital-100539264997895/",
      title: "Facebook",
    },
    {
      icon: Instagram,
      href: "https://www.instagram.com/goriladigital/",
      title: "Instagram",
    },
    {
      icon: Twitter,
      href: "https://twitter.com/goriladigital",
      title: "Twitter",
    },
  ],
  footer: {
    section1: {
      text: `Por favor, contacta con nosotros a través de nuestras redes sociales o email. Damos servicio en todo el mundo.`,
    },
    contact: {
      title: "Contacto",
      sections: [
        {
          icon: MapMarker,
          text: textToMultiline(`Málaga\n España`),
        },
        {
          icon: Skype,
          text: "live:.cid.c87adf2d38ea7135",
        },
        {
          icon: Envelope,
          text: "hola@goriladigital.es",
        },
      ],
    },
    section3: {
      title: "Organiza un meeting",
      text: `Vamos al grano. Mira nuestro calendario y selecciona un hueco libre que te venga bien para una videollamada.`,
      cta: {
        text: "Abrir el calendario",
        href:
          "https://calendar.google.com/calendar/selfsched?sstoken=UUNjdmlsRGlwRndffGRlZmF1bHR8MDQ2N2I1OTVjZjI0ODQ3NWFhMTI5YzNiMGYwMTcwN2Y",
      },
    },
  },
  copyright: "© 2020 Gorila Digital",
};
